import { AES, enc } from 'crypto-js';
import { secretPass } from '../constants/SecretConstant'

export const setSessionStorage = (res, data) => {
  // const encrytData=AES.encrypt(JSON.stringify({data}),secretPass).toString();
  // sessionStorage.setItem(res,encrytData)
  let encrytData = AES.encrypt(JSON.stringify(data), secretPass).toString();
  sessionStorage.setItem(res, encrytData)
  //  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
}
export const getSessionStorage = (res) => {
  // const decryptedData = sessionStorage.getItem(res)
  // alert(AES.decrypt(decryptedData, secretPass).toString(enc.Utf8))
  // return AES.decrypt(decryptedData, secretPass).toString(enc.Utf8)
  const decryptedData = sessionStorage?.getItem(res);
  if (decryptedData) {
    return AES.decrypt(decryptedData, secretPass).toString(enc.Utf8);
  } 
  else {
   // console.error("No data found in sessionStorage for key:", res);
    return null; // or handle it according to your application logic
  }
}