import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patchWiseCustomers: [],
};

const patchWiseCustomerSlice = createSlice({
  name: "patchWiseCustomer",
  initialState,
  reducers: {
    setCustomers(state, action) {
      return {
        ...state,
        patchWiseCustomers: action.payload,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const patchWiseCustomerActions = patchWiseCustomerSlice.actions;
export const patchWiseCustomerReducers = patchWiseCustomerSlice.reducer;
