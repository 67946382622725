import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messageType: "",
  messageContent: "",
};

const messageApiSlice = createSlice({
  name: "messageApi",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      return {
        ...state,
        messageType: action.payload.messageType,
        messageContent: action.payload.messageContent,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const messageApiActions = messageApiSlice.actions;
export const messageApiReducers = messageApiSlice.reducer;
