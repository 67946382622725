import { createSlice } from "@reduxjs/toolkit";
import api from "../../services/AccountService";
import { loaderActions } from "./MasterLoader";

const initialState = {
  isLoggedIn: false,
  result: {},
  carryFlag: "mobile",
  formObj: {
    CLIENTID: "",
    REPSNAME: "",
    PWD: "",
  },
  errorMessage: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequested(state) {
      return {
        ...state,
        loading: true,
      };
    },
    loginSuccess(state, action) {
      return {
        ...state,
        isLoggedIn: true,
        result: action.payload,
      };
    },
    loginFailed(state, action) {
      return {
        ...state,
        isLoggedIn: false,
        result: action.payload.resultSet,
        errorMessage: action.payload.errorMessage,
      };
    },
    logout(state) {
      sessionStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        sessionData: {},
        result: {},
      };
    },
  },
});


export const authActions = authSlice.actions;
export const authReducers = authSlice.reducer;
