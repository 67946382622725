import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  calenderWithPatches: [],
};

const stpSelectPatchSlice = createSlice({
  name: "selectPatch",
  initialState,
  reducers: {
    setCalenderWithPatches(state, action) {
      return {
        ...state,
        calenderWithPatches: action.payload,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const stpSelectPatchActions = stpSelectPatchSlice.actions;
export const stpSelectPatchReducers = stpSelectPatchSlice.reducer;
