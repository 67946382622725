import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoader(state, action) {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const loaderActions = loaderSlice.actions;
export const loaderReducers = loaderSlice.reducer;
