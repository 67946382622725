import axios from "axios";
import { refreshToken } from "../api/AuthApi";

const api = axios.create({
  //  baseURL: "https://localhost:44342/api", //https
baseURL: "https://dev1.cirrius.in/CirriusAccountAPI/api",
  // baseURL: "https://reactapp.cirrius.in/CirriusAccountAPI/api", //UAT
  // baseURL: "https://reactapi.cirrius.in/CirriusAccountAPI/api", //Live
  // baseURL: "https://dev1.cirrius.in/CirriusAPIGateway/api",
  //baseURL:process.env.REACT_APP_ACCOUNT_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem("token");
    //Expired Token for testing expired time
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VybmFtZSI6IlRlc3QxMDEiLCJDTElFTlRJRCI6IkNQMyIsIlBXRCI6IlQwMDAxIiwibmJmIjoxNjg3MzMyNzgxLCJleHAiOjE1ODczMzYzODksImlhdCI6MTY4NzMzMjc4MX0.aO-rpJZtTf4goSeGwevPodXwC_jMF2rFmrsnqqOBOVg"
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // setSessionData(response.data);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log("error?.response",error?.response);
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // let decryptedData = JSON.parse(AES.decrypt(refreshToken).toString(enc.Utf8));
      // console.log('decryptedData',decryptedData)
      const accessToken = refreshToken();
      if (accessToken) {
        api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
        return api(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
