import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userSession } from "../../../constants/UserConstant";
// import { reportsApi } from "../../../components/common/ReportsAPI";

const userData = userSession.userData;

const reportsApi = axios.create({
    baseURL: "https://localhost:7254/api",
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    },
});

reportsApi.interceptors.request.use(
    async (config) => {
        const token = sessionStorage.getItem("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },

    (error) => Promise.reject(error)
);

reportsApi.interceptors.response.use(
    (response) => response,
    async (error) => Promise.reject(error)
);

export const BindGenReportRedux = createAsyncThunk("BindReportRedux", async (dataObj) => {
    const postData = {
        CLIENTID: userData.CLIENTID,
        BU: dataObj.bu,
        REPCODE: userData.REPCODE,
        LEVEL: dataObj.level,
        LOCATION: dataObj.location,
        USER: dataObj.user,
        CUST_TYPE: dataObj.cust_type
    }
    const data = {
        key: "",
        dataobj: "",
        jsonstring: JSON.stringify(postData),
    }
    try {
        const response = await reportsApi.post('/v1/Reports/BindGenData', JSON.stringify(data));
        return response.data?.resultSet;
    }
    catch (error) {
        throw error;
    }
});

export const getReports = createAsyncThunk("getReports", async () => {
    const postData = {
        CLIENTID: userData.CLIENTID,
        FILTERID: 2074,
        REPCODE: userData.REPCODE,
        BU: userData.BU,
    }

    const data = {
        key: "",
        dataobj: "",
        jsonstring: JSON.stringify(postData)
    }
    try {
        const response = await reportsApi.post('/v1/Reports/GetGenRptData', JSON.stringify(data));
        return response.data?.resultSet;
    }
    catch (error) {
        throw error;
    }
})

export const getBUData = createAsyncThunk("getBUData", async (choice) => {
    console.log(choice);
    const postData = {
        CLIENTID: userData.CLIENTID,
        REPCODE: userData.REPCODE,
        CHOICE: choice,
    }
    const data = {
        key: "",
        dataobj: "",
        jsonstring: JSON.stringify(postData)
    }
    try {
        const response = await reportsApi.post('/v1/Reports/ShowBu', JSON.stringify(data))
        return response.data?.resultSet;
    }
    catch (error) {
        throw error;
    }
});

export const getCustomerType = createAsyncThunk("getCustomerType", async (choice) => {
    console.log(choice);
    const postData = {
        CLIENTID: userData.CLIENTID,
        REPCODE: userData.REPCODE,
        CHOICE: choice,
    }
    const data = {
        key: "",
        dataobj: "",
        jsonstring: JSON.stringify(postData)
    }
    try {
        const response = await reportsApi.post('/v1/Reports/ShowBu', JSON.stringify(data))
        return response.data?.resultSet;
    }
    catch (error) {
        throw error;
    }
});

export const getLevelData = createAsyncThunk("getLevelData", async (bu) => {
    const postData = {
        CLIENTID: userData.CLIENTID,
        REPCODE: userData.REPCODE,
        BU: bu,
    }
    const data = {
        key: "",
        dataobj: "",
        jsonstring: JSON.stringify(postData)
    }
    try {
        const response = await reportsApi.post('/v1/Reports/ShowLevel', JSON.stringify(data))
        return response.data?.resultSet;
    }
    catch (error) {
        throw error;
    }
});

export const getLocation = createAsyncThunk("getLocation", async ({bu, level}) => {
    // console.log('axios call');
    const postData = {
        CLIENTID: userData.CLIENTID,
        REPCODE: userData.REPCODE,
        BU: bu,
        GEOLEVEL: level,
    }
    const data = {
        key: "",
        dataobj: "",
        jsonstring: JSON.stringify(postData)
    }
    try {
        const response = await reportsApi.post('/v1/Reports/ShowLocation', JSON.stringify(data))
        return response.data?.resultSet;
    }
    catch (error) {
        throw error;
    }
});

export const getUser = createAsyncThunk('getUser', async ({bu, level, location}) => {
    const postData = {
        CLIENTID: userData.CLIENTID,
        REPCODE: userData.REPCODE,
        BU: bu,
        GEOLEVEL: level,
        GEOCODE: location,
    }
    const data = {
        key: "",
        dataobj: "",
        jsonstring: JSON.stringify(postData)
    }
    try {
        const response = await reportsApi.post('/v1/Reports/ShowUser', JSON.stringify(data))
        return response.data?.resultSet;
    }
    catch (error) {
        throw error;
    }
});

const initialState = {
    reportData: [],
    buData: [],
    levelData: [],
    locData: [],
    repData: [],
    repHeader: [],
    userData: [],
    cust_type: [],
    isRptLoad: false,
    isLoading: false,
    isBULoading: false,
    isLevelLoading: false,
    isLocationLoading: false,
    isUserLoading: false,
    isCustomerType: false
}


const genReportsSlice = createSlice({
    name: "genReportsSlice",
    initialState,
    reducers: {
        updateLocData: (state,action) =>{
            state.locData = action.payload;
        },
        updateUserData: (state,action) =>{
            state.userData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(BindGenReportRedux.fulfilled, (state, action) => {
                state.repData = action.payload;
                state.repHeader = action.payload;
                state.isLoading = false;
            })
            .addCase(getReports.fulfilled, (state, action) => {
                state.reportData = action.payload;
                state.isRptLoad = false;
            })
            .addCase(getBUData.fulfilled, (state, action) => {
                state.buData = action.payload;
                state.isBULoading = false;
            })
            .addCase(getCustomerType.fulfilled, (state,action) =>{
                state.cust_type = action.payload;
                state.isCustomerType = false;
            })
            .addCase(getLocation.fulfilled, (state, action) => {
                state.locData = action.payload;
                state.isLocationLoading = false;
            })
            .addCase(getLevelData.fulfilled, (state, action) => {
                state.levelData = action.payload;
                state.isLevelLoading = false;
            })
            .addCase(getUser.fulfilled, (state,action) =>{
                state.userData = action.payload;
                state.isUserLoading = false;
            })
            .addCase(getBUData.pending, (state, action) => {
                state.isBULoading = true;
            })
            .addCase(getCustomerType.pending, (state,action) =>{
                state.isCustomerType = true;
            })
            .addCase(getReports.pending, (state, action) => {
                state.isRptLoad = true;
            })
            .addCase(getLevelData.pending, (state, action) => {
                state.isLevelLoading = true;
            })
            .addCase(getLocation.pending, (state, action) => {
                state.isLocationLoading = true;
            })
            .addCase(getUser.pending, (state,action) =>{
                state.isUserLoading = true;
            })
            .addCase(BindGenReportRedux.pending, (state, action) => {
                state.isLoading = true;
            })
    }
})

export const { updateLocData,updateUserData } = genReportsSlice.actions;

export default genReportsSlice.reducer;