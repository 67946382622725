import { createSlice } from "@reduxjs/toolkit";
import { loaderActions } from "./MasterLoader";
import api from "../../services/AccountService";
import { findNestedObject } from "../../utils/menu";
import { getConfigData } from "../../utils/config";
import { stpIndexActions } from "./STP/stpIndexSlice";
import {getSessionStorage}from '../../utils/storageSecurity'
const initialState = {
  // loading: false,
  menus: [],
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    bindMenus(state, action) {
      return {
        ...state,
        menus: action.payload,
      };
    },
  },
});

export const getMenus = () => async (dispatch) => {
  dispatch(loaderActions.setLoader(true));
  const userData= JSON.parse(getSessionStorage("userData"));
  const postData = {
    CLIENTID: userData.CLIENTID,
    REPCODE: userData.REPCODE,
    ROLECODE: userData.ROLECODE,
  };
  const data = {
    key: "",
    dataobj: "",
    jsonstring: JSON.stringify(postData),
  };
  const response = await api.post("/v1/Account/GetMenu", JSON.stringify(data));
  dispatch(menuActions.bindMenus(response.data.resultSet));
  dispatch(loaderActions.setLoader(false));
};

export const setConfigSettigs = async (moduleId, setConfigData) => {
  // debugger;
  // const menuItem = findNestedObject(menus, menuCode);
  const configSettings = await getConfigData(moduleId);
  // dispatch(stpIndexActions.setConfigSettings(configSettings));
  setConfigData(configSettings);
};

export const setConfigSettigsForManager = async (moduleId, setConfigData) => {
  const configSettings = await getConfigData(null, moduleId);
  setConfigData(configSettings);
};

export const menuActions = menuSlice.actions;
export const menuReducers = menuSlice.reducer;
