import api from "../services/AccountService";
import ClientError from "../utils/errorLogger";
import { getSessionStorage } from "../utils/storageSecurity";
import { userSession } from "../constants/UserConstant";
export const getConfigs = async () => {
  let userData = JSON.parse(getSessionStorage("userData"));
  const configData = {
    // CLIENTID: sessionStorage.getItem("clientId"),
    CLIENTID:JSON.parse(getSessionStorage("clientId")),
    BU: userData.BU,
    TaskForce: userData.TASKFORCE,
    Rolecode: userData.ROLECODE,
  };
  const Configuredata = {
    key: "",
    dataobj: "",
    jsonstring: JSON.stringify(configData),
  };
  try {
    return await api.post("/v1/account/getconfig", JSON.stringify(Configuredata));
  } catch (error) {
    //console.log("Refresh token failed", error.response);
 // return ClientError(error.message, window.location, `${error.config.baseURL}${error.config.url}`,error.response);
     throw error;
  }
  // return await api.post("/v1/account/getconfig", JSON.stringify(Configuredata));
};

//login action creator
export const login = async (data) => {
  console.log('data',data)
  try {
    const loginData= await api.post("/v1/account/signin", JSON.stringify(data));
    return loginData
  } catch (error) {
   // console.log("Refresh token failed", error.response);
   /// return statement was removed from ClientError because its not able to throw any error that will generate Invalid Login Message
     ClientError(error.message, window.location, `${error.config.baseURL}${error.config.url}`,error.request, error.response);
    throw error;
  }
};

export const refreshToken = async () => {
  // const userData = JSON.parse(getSessionStorage("userData"));
  const userData= JSON.parse(getSessionStorage("userData"));
  console.log('getSessionStorage',userData)
  // const statusData = JSON.parse(sessionStorage.getItem("statusData"));
  const statusData = JSON.parse(getSessionStorage("statusData"));


  const postData = {
    // refreshToken: sessionStorage.getItem("refreshToken"),
    CLIENTID: userData?.CLIENTID,
    REPSNAME: statusData?.repname?.split("-")[0],
  };
  const data = {
    key: "",
    dataobj: "",
    jsonstring: JSON.stringify(postData),
  };

  try {
    const response = await api.post(
      "/v1/account/RefreshToken",
      JSON.stringify(data)
    );
    const { token } = response.data;
    sessionStorage.setItem("token", token);
    return token;
  } catch (error) {
   // console.log("Refresh token failed", error);
    //ClientError(error.message, window.location, `${error.config.baseURL}${error.config.url}`, error.response);
  }
};
