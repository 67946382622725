
// errorLogger.js
import axios from 'axios';
import {getSessionStorage} from './storageSecurity'
import { userSession } from '../constants/UserConstant';
// import { userData } from '../redux/slices/MTP/Constant';
// const userData = JSON.parse(getSessionStorage("userData"));
// const userData= JSON.parse(getSessionStorage("userData"));
const userData = userSession.userData;

// console.log('userDataError',userData)
// const formattedDate = new Date().toISOString().split('T')[0];
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so we add 1
// const params =useParams()
// console.log('params',params)

const ClientError = async (error, fileName, apiUrl, request, response) => {
    console.log('resss', error, response);

    // Capture client-side errors
    window.onerror = function (message, source, lineno, colno, error) {
        const clientErrorLog = {
            message,
            source,
            lineno,
            colno,
            error: error ? error.stack : null,
        };

        console.log('Client-side error:', clientErrorLog);

        // Log the client-side error along with other error information
        ErrorLogger(error, fileName, apiUrl, request, response, clientErrorLog);
    };
}
const ErrorLogger = async (error, fileName, apiUrl, request, response,clientErrorLog) => {
    console.log('resss',clientErrorLog)
    
    var repCode;
    if (userData && userData.REPCODE) {
        repCode = userData.REPCODE
        var logEntryFileName = `${userData.CLIENTID}/${month}-${year}/${repCode}_ErrorLogs.txt`;
        // var logEntryFileName = `${repCode}_${formattedDate}_ErrorLogs.txt`;
        console.log('logEntryFileName',logEntryFileName)
        // const blobUrl = `https://storagegpworker.blob.core.windows.net/react/${logEntryFileName}?sp=racwl&st=2023-12-05T05:47:44Z&se=2024-12-31T13:47:44Z&spr=https&sv=2022-11-02&sr=c&sig=3iXmAY%2F8ev%2BskeZK71HOMWG%2BWXMg2B0TI11eHzk2V9I%3D`; // Replace with your actual Blob URL
    }
    else {
        repCode = 'Common'
        // var logEntryFileName = `${repCode}_${formattedDate}_ErrorLogs.txt`;
        var logEntryFileName = `logs/${year}/${month}/${repCode}_ErrorLogs.txt`;
        console.log('logEntryFileName',logEntryFileName)
    }
    try {
        const blobUrl = `https://storagegpworker.blob.core.windows.net/react/${logEntryFileName}?sp=racwl&st=2023-12-05T05:47:44Z&se=2024-12-31T13:47:44Z&spr=https&sv=2022-11-02&sr=c&sig=3iXmAY%2F8ev%2BskeZK71HOMWG%2BWXMg2B0TI11eHzk2V9I%3D`; // Replace with your actual Blob URL
        // const logEntryFileName = `${repCode}_${formattedDate}_ErrorLogs.txt`;
        // const blobUrl = `https://storagegpworker.blob.core.windows.net/react/${logEntryFileName}?sp=racwl&st=2023-12-05T05:47:44Z&se=2024-12-31T13:47:44Z&spr=https&sv=2022-11-02&sr=c&sig=3iXmAY%2F8ev%2BskeZK71HOMWG%2BWXMg2B0TI11eHzk2V9I%3D`; // Replace with your actual Blob URL
        // Create a timestamp
        const timestamp = new Date().toLocaleString();
        console.log('response',response)
        const responseData = {
            // data: response.data,
            // headers: response.headers,
            // status: response.status,
            config: {
                baseURL: request.config.baseURL,
                method: request.config.method,
                url: request.config.url,
                data: JSON.parse(request.config.data)
            }
        }
        const logEntry = `${timestamp} - ${fileName.pathname}\n\nError: ${error}\n\nAPI URL: ${apiUrl}\n\nrequest: ${JSON.stringify(responseData, null, 2)}\n\nResponse: ${JSON.stringify(response, null, 2)}\n\n ***********************************************************************************************\n`;
        // Read existing content from the blob
        const existingContent = await readBlobContent(blobUrl);
        // Combine existing content with the new log entry
        const combinedContent = `${logEntry}${existingContent}`;
        // Add headers as needed
        const headers = {
            'Content-Type': 'multipart/form-data',
            'x-ms-blob-type': 'BlockBlob',
            // Add any other headers as needed
        };

        // Use axios to upload the combined content back to the blob
        await axios.put(blobUrl, combinedContent, { headers });
        console.log('Error logged successfully');
    } catch (err) {
        console.error('Error logging error:', err);
        return err
    }
};

const readBlobContent = async (blobUrl) => {
    try {
        var repCode;
        if (userData.REPCODE == null) {
            repCode = 'Common'
        }
        else {
            repCode = userData.REPCODE
        }
        // const logEntryFileName = `${repCode}_${formattedDate}_ErrorLogs.txt`;
        const logEntryFileName = `${userData.CLIENTID}/${month}-${year}/${repCode}_ErrorLogs.txt`;
        const response = await axios.get(`https://storagegpworker.blob.core.windows.net/react/${logEntryFileName}?sp=racwl&st=2023-12-05T05:47:44Z&se=2024-12-31T13:47:44Z&spr=https&sv=2022-11-02&sr=c&sig=3iXmAY%2F8ev%2BskeZK71HOMWG%2BWXMg2B0TI11eHzk2V9I%3D`);
        return response.data; // Assuming the content is text-based
    } catch (error) {
        return ''; // Return an empty string if there's an error
    }
};


export default ClientError;
