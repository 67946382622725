import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  configSettings: null,
  result: null,
  errorMessage: null,
};

const stpIndexSlice = createSlice({
  name: "stpIndex",
  initialState,
  reducers: {
    onSuccess(state, action) {
      return {
        ...state,
        result: action.payload,
      };
    },
    onFail(state, action) {
      return {
        ...state,
        errorMessage: action.payload,
      };
    },
    setConfigSettings(state, action) {
      return {
        ...state,
        configSettings: action.payload,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const stpIndexActions = stpIndexSlice.actions;
export const stpIndexReducers = stpIndexSlice.reducer;
