import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patchWiseCustomers: [],
};

const stpAddCustomerSlice = createSlice({
  name: "addCustomer",
  initialState,
  reducers: {
    setCustomers(state, action) {
      return {
        ...state,
        patchWiseCustomers: action.payload,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const stpAddCustomerActions = stpAddCustomerSlice.actions;
export const stpAddCustomerReducers = stpAddCustomerSlice.reducer;
