import { Spin } from 'antd'
import React from 'react'


const MasterLoader = () => {
  return (
    <>
    <div className="top-loader"></div>
    <div className="masterapploading"></div>
    </>
    // <div className='Loader'>cdw</div>
    // <div className='Loader'><Spin></Spin><div className='overalay'></div></div>
  )
}

export default MasterLoader;