import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./assets/css/style.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import MasterLoader from "./components/common/MasterLoader";
import LoadingGIF from '../src/assets/images/app-loading.gif'
import MenuMasterLoader from "./components/common/MenuMasterLoader";

const App = React.lazy(() => import('./App'));

// For Suspense FallBack
// const SuspenseLoading = () =>{
//   return(
//     <div className="loader_full-width">
//       <div className="loader-wrapper">
//         <img src={LoadingGIF} />
//       </div>
//     </div>
//   )
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const RootComponent = () => {
  return (
    // <React.StrictMode>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<MenuMasterLoader />}>
            <App />
          </Suspense>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Provider>
    // </React.StrictMode>
  );
};

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <QueryClientProvider client={queryClient}>
//         <App />
//         <ReactQueryDevtools />
//       </QueryClientProvider>
//     </Provider>
//   </React.StrictMode>
// );

root.render(<RootComponent />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
