import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: sessionStorage.getItem("token"),
  userData: null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession(state, action) {
      return {
        ...state,
        token: action.payload.token,
        userData: action.payload.userData,
      };
    },
  },
});

export const sessionActions = sessionSlice.actions.session;
export const sessionReducers = sessionSlice.reducer;
