import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dailyActivityList: [],
  patchWiseCustomers: {},
};

const stpDailyActivitySlice = createSlice({
  name: "dailyActivity",
  initialState,
  reducers: {
    setDailyActivityList(state, action) {
      return {
        ...state,
        dailyActivityList: action.payload,
      };
    },
    setPatchWiseCustomerList(state, action) {
      return {
        ...state,
        patchWiseCustomers: action.payload,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const stpDailyAcitvityActions = stpDailyActivitySlice.actions;
export const stpDailyAcitvityReducers = stpDailyActivitySlice.reducer;
