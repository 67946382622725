import { getSessionStorage } from "./storageSecurity";
export const getConfigData = async (moduleId) => {
  const configData=JSON.parse(getSessionStorage("config"))
  const config = JSON.parse(configData);
  let configObj = null;
  // if (menuItem !== null) {
  //   configObj = config.find((el) => el.moduleid === menuItem.moduleid);
  // }
  if (moduleId !== null) {
    configObj = config.find((el) => el.moduleid === moduleId);
  }

  if (configObj) {
    const configJson =
      await require(`../assets/json/${configObj.modulename}/config.json`);

    if (configJson) {
      const getConfigSettings = configJson.find(
        (con) => con.configMode === configObj.configmode
      );
      return getConfigSettings;
    }
  }
};

export const getStpPathFromConfig = (menuCode) => {
  const configJson = require(`../assets/json/ApprovalConfig/config.json`);
  if (configJson) {
    return configJson?.find((item) => item?.menuCode === menuCode && item)
  }
};
