import { Spin } from 'antd'
import React from 'react'


const MenuMasterLoader = () => {
  return (
    // <>
    // <div class="top-loader"></div>
    // <div class="masterapploading"></div>
    // </>
    <div className='Loader bg-white'><div className='childPlasewait Plasewait'><span>Please Wait </span></div></div>
    // <div className='Loader'><Spin></Spin><div className='overalay'></div></div>
  )
}

export default MenuMasterLoader;