import { configureStore } from "@reduxjs/toolkit";
import { authReducers } from "./slices/authSlice";
import { menuReducers } from "./slices/menuSlice";
import { loaderReducers } from "./slices/MasterLoader";
import STPReducers from "./slices/STP/STPReducers";
import { messageApiReducers } from "./slices/messageApiSlice";
import { modalReducers } from "./slices/modal";
import { sessionReducers } from "./slices/sessionSlice";
import genReportsSlice from "./slices/Reports/reportsSlice";

const store = configureStore({
  reducer: {
    auth: authReducers,
    menu: menuReducers,
    loader: loaderReducers,
    messageApi: messageApiReducers,
    stp: STPReducers,
    modal: modalReducers,
    session: sessionReducers,
    reports: genReportsSlice,
  },
});

export default store;
