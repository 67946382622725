import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openModal: false,
};

const stpCustomerListModalSlice = createSlice({
  name: "customerListModal",
  initialState,
  reducers: {
    setModalState(state, action) {
      return {
        ...state,
        openModal: action.payload,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const stpCustomerListModalActions = stpCustomerListModalSlice.actions;
export const stpCustomerListModalReducer = stpCustomerListModalSlice.reducer;
