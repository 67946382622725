import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  modalProps: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    // openOrCloseModal(state, action) {
    //   return {
    //     ...state,
    //     open: action.payload,
    //   };
    // },
    setModalProps(state, action) {
      return {
        ...state,
        props: { ...action.payload },
      };
    },
  },
});

export const modalActions = modalSlice.actions;
export const modalReducers = modalSlice.reducer;
