import { combineReducers } from "@reduxjs/toolkit";
import { stpIndexReducers } from "./stpIndexSlice";
import { stpCalenderReducers } from "./stpCalenderSlice";
import { stpCalenderSummaryReducers } from "./stpCalendarSumSlice";
import { stpAddCustomerReducers } from "./stpAddCustomerSlice";
import { stpDailyAcitvityReducers } from "./stpDailyActivitySlice";
import { stpCustomerListModalReducer } from "./stpCustomerListModalSlice";
import { stpSelectPatchReducers } from "./stpSelectPatchSlice";
import { patchWiseCustomerReducers } from "./patchWiseCustomerSlice";

export default combineReducers({
  index: stpIndexReducers,
  calender: stpCalenderReducers,
  calendarSummary: stpCalenderSummaryReducers,
  addCustomer: stpAddCustomerReducers,
  dailyActivity: stpDailyAcitvityReducers,
  customerListModal: stpCustomerListModalReducer,
  selectPatch: stpSelectPatchReducers,
  patchWiseCustomer: patchWiseCustomerReducers,
});
